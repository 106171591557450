import React, { useState } from "react";
import { Info, Link as LinkIcon } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";

import { UrlTargetingSchema } from "../../../../schemas";
import usePasteUrlTargeting from "../../../../datahooks/usePasteUrlTargeting";
import { useSearchParamsContext } from "./SearchParamsContext";

export default function PasteUrlTargeting({
  hasSearchParams,
}: {
  hasSearchParams: boolean;
}) {
  const isDesktop = useTwBreakpoint("lg");

  const { setSearchParams } = useSearchParamsContext();

  const [isOpen, setIsOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const { getSearchParamsFromUrl, isGettingSearchParamsFromUrl } =
    usePasteUrlTargeting();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "",
    },
    resolver: valibotResolver(UrlTargetingSchema),
  });

  function resetState() {
    reset();
    setStepIndex(0);
  }

  function onNext({ url }: { url: string }) {
    if (hasSearchParams && stepIndex === 0) {
      setStepIndex(1);
    } else {
      getSearchParamsFromUrl({ url }).then((result) => {
        setSearchParams(result);
        setIsOpen(false);
        resetState();
      });
    }
  }

  // TODO: Hide Paste URL feature for now, until backend is implemented
  return null;

  const Component = isDesktop ? Dialog : Drawer;
  const Trigger = isDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isDesktop ? DialogContent : DrawerContent;
  const Close = isDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen} onAfterClose={resetState}>
      <Trigger asChild>
        <Button
          className="max-lg:mb-4 lg:ml-auto"
          size={isDesktop ? "sm" : "md"}
          leftIcon={<LinkIcon />}
          variant="tertiary-black"
        >
          Paste URL Targeting
        </Button>
      </Trigger>
      <Content>
        <h3 className="mb-2 text-center text-headline-2xl-bold lg:text-headline-xl-bold">
          {stepIndex === 0
            ? "Enter URL of audience from LinkedIn"
            : "Overwrite targeting?"}
        </h3>

        {stepIndex === 0 ? (
          <>
            <Label htmlFor="search-url" className="mb-1 mt-6">
              URL
            </Label>
            <Input
              name="search-url"
              id="search-url"
              variant="lg"
              placeholder="Enter URL here..."
              error={errors.url?.message}
              {...register("url")}
            />
            <div className="mb-8 mt-1 flex gap-x-0.5 text-black-700">
              <Info weight="fill" size={16} />
              <p className="text-caption-12-regular">
                Applying URL over a set targeting will overwrite the settings.
                This cannot be undone
              </p>
            </div>
          </>
        ) : (
          <p className="mb-8 text-center text-black-500">
            This will overwrite any settings in your targeting. This cannot be
            undone. Do you wish to proceed?
          </p>
        )}

        <div className="flex flex-col gap-2 lg:flex-row-reverse lg:gap-4">
          <Button
            className="flex-1"
            onClick={handleSubmit(onNext)}
            isLoading={isGettingSearchParamsFromUrl}
          >
            {stepIndex === 0 ? "Next" : "Apply targeting"}
          </Button>
          <Close asChild>
            <Button
              className="flex-1"
              variant="secondary-black"
              onClick={() => setIsOpen(false)}
              disabled={isGettingSearchParamsFromUrl}
            >
              Cancel
            </Button>
          </Close>
        </div>
      </Content>
    </Component>
  );
}
